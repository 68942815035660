/* App.css */

.floating-button {
    position: fixed;
    bottom: 50px; /* Adjust the value as needed to control the vertical position */
    right: 20px; /* Adjust the value as needed to control the horizontal position */
    z-index: 1000; /* Adjust the z-index as needed to ensure it appears on top */
}


/* Styles for the graph container */
.graph-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

/* Styles for the toggle button */
.toggle-button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
}

/* Styles for the panel */
.panel {
    position: absolute;
    top: 0;
    left: -300px;
    /* Initial position outside the 
    viewport */
    width: 300px;
    height: 100%;
    background-color: #f7f7f7;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease-in-out;
    overflow-y: auto;
}

/* Styles for the open panel */
.panel.open {
    left: 0;
    /* Slide in from the left when open */
}

/* Styles for the panel content */
.panel>div {
    padding: 16px;
}

/* Additional styles as needed */

/* Add this CSS to your existing stylesheet or create a new CSS file */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    max-width: 300px;
}

.modal h2 {
    font-size: 18px;
    margin: 0 0 10px;
}

.modal input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal button {
    display: inline-block;
    padding: 6px 12px;
    margin-right: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal button:hover {
    background-color: #0056b3;
}

.modal button:last-child {
    background-color: #ccc;
}

.modal button:last-child:hover {
    background-color: #999;
}